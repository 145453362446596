import 'dayjs/locale/ja'

import dayjs, { ConfigType, OptionType, OpUnitType } from 'dayjs'
import timezonePlugin from 'dayjs/plugin/timezone'
import utcPlugin from 'dayjs/plugin/utc'

dayjs.extend(utcPlugin)
dayjs.extend(timezonePlugin)

// WARNING: タイムゾーンJSTに固定するため、dayjs() を使用する場合は当ラッパーを必ず使用する
export const day = (date?: ConfigType, format?: OptionType) =>
  dayjs(date, format).tz('Asia/Tokyo')

// formatを省略するとISO8601となる (dayjsのデフォルト。ミリ秒は無し)
export const formatDate = (
  date: Date | string,
  format?:
    | 'YYYY/MM/DD'
    | 'YYYY-MM-DD'
    | 'YYYY年MM月DD日'
    | 'YYYY年MM月DD日(dd)'
    | 'YYYY年MM月DD日(dd) HH:mm:ss'
    | 'YYYY/MM/DD HH:mm:ss'
    | 'YYYYMMDDHHmmss'
    | 'MM月DD日'
) => dayjs(date).locale('ja').format(format)

// dayjsのisValidだと存在しない日付もvalidになってしまうため使用しない
export const isValidDate = (value: string | Date) =>
  !Number.isNaN(new Date(value).getTime())

// 日付をURLのクエリーに置き換える
// encodeURIComponentメソッドでは「:」もエンコードされてしまうため+のみ置き換える
export const dateToQueryParam = (date: Date) =>
  dayjs(date).locale('ja').format('YYYY-MM-DDTHH:mm:ssZ').replace(/\+/g, '%2b')

export const add1second = (date: Date) => addDate(date, 1, 'second')

export const addDate = (date: Date, value: number, unit?: OpUnitType) =>
  dayjs(date).locale('ja').add(value, unit).toDate()

/**
 * DateRange
 */

export type DateRange = {
  from: Date
  to: Date
}

export const dateRangeToQueryString = ({ from, to }: DateRange) =>
  `from=${dateToQueryParam(from)}&to=${dateToQueryParam(to)}`

export const getDateRange = (base: Date, unit: OpUnitType): DateRange => {
  const from = dayjs(base).startOf(unit)
  const to = dayjs(base).endOf(unit)
  return { from: from.toDate(), to: to.toDate() }
}

/**
 * YearMonth
 */

export type YearMonth = {
  year: number
  month: number
}

export const formatYearMonth = (
  { year, month }: YearMonth,
  format?: 'YYYY年MM月' | 'YYYYMM'
) =>
  dayjs(new Date(year, month - 1))
    .locale('ja')
    .format(format)

export const getThisYearMonth = () => {
  const now = dayjs()
  return { year: now.year(), month: now.month() + 1 }
}

export const addYearMonth = ({ year, month }: YearMonth, value: number) => {
  const date = dayjs(new Date(year, month - 1)).add(value, 'month')
  return { year: date.year(), month: date.month() + 1 }
}

export const yearMonthToQueryString = ({ year, month }: YearMonth) =>
  `year=${year}&month=${month}`

export const calculateAge = (birthdate: Date) => dayjs().diff(birthdate, 'year')
